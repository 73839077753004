<template>
  <!--  新增回访 -->
  <div class="add-visit-form">
    <div class="travelInfo">
      <el-row>
        <el-col :span="8">
          <span class="label">线路名称：</span>
          <span class="value"
            >{{ rowInfo.startStation }}-{{ rowInfo.endStation }}</span
          >
        </el-col>
        <el-col :span="8">
          <span class="label">状态：</span>
          <span class="value">{{
            orderStatusList.find((t) => t.value == rowInfo.orderStatus).label
          }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">订单编号：</span>
          <span class="value">{{ rowInfo.orderId }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">下单人：</span>
          <span class="value">{{ rowInfo.orderPhone }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">联系电话：</span>
          <span class="value">{{ rowInfo.orderPhone }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">乘客数量：</span>
          <span class="value">{{ rowInfo.passengerCount }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">有效乘车人数：</span>
          <span class="value">{{ rowInfo.validPassengersNum }}</span>
        </el-col>
        <el-col :span="8">
          <span class="label">下单时间：</span>
          <span class="value">{{ rowInfo.createTime }}</span>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" :model="ruleForm" :rules="rules" label-width="120px">
      <el-form-item label="被访人：">
        <el-input
          v-model="ruleForm.interviewee"
          placeholder="请填写被访人"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item label="回访人：" prop="interviewer">
        <el-input
          v-model="ruleForm.interviewer"
          placeholder="请填写回访人"
          disabled
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item label="回访时间：" prop="interviewTime">
        <el-date-picker
          type="datetime"
          v-model="ruleForm.interviewTime"
          value-format="timestamp"
          placeholder="请选择回访时间"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="回访方式：" prop="interviewWay">
        <el-radio-group v-model="ruleForm.interviewWay">
          <el-radio :label="0">电话回访</el-radio>
          <el-radio :label="1">微信回访</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="回访内容：" prop="interviewContent">
        <el-radio-group v-model="ruleForm.interviewContent">
          <el-radio :label="0">产品使用情况</el-radio>
          <el-radio :label="1">客诉处理</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="回访记录：" prop="interviewRecord" class="initLine">
        <quill-editor
          ref="myTextEditor"
          v-model="ruleForm.interviewRecord"
          :options="editorOption"
        ></quill-editor>
      </el-form-item>
      <el-form-item label="上传附件：">
        <el-upload
          name="files"
          :action="uploadUrl"
          :multiple="true"
          :before-upload="beforeUpload"
          :on-progress="uploadProgress"
          :on-success="uploadSuccess"
          :on-error="uploadError"
          :show-file-list="false"
          :limit="9"
        >
          <el-button>
            <i class="el-icon-plus"></i>
            点击上传</el-button
          >
        </el-upload>
        <ul class="file-list">
          <li v-for="(item, index) in fileList" :key="index">
            <img
              v-if="item.file.type.indexOf('image') != -1"
              src="@/assets/images/home/image.svg"
              alt=""
            />
            <img
              v-if="item.file.type.indexOf('video') != -1"
              src="@/assets/images/home/video.svg"
              alt=""
            />
            <img
              v-if="item.file.type.indexOf('audio') != -1"
              src="@/assets/images/home/audio.svg"
              alt=""
            />
            <span class="img-name">
              {{ item.name }}
            </span>
            <span class="delete" @click="fileList.splice(index, 1)">删除</span>
            <el-progress
              v-if="item.percent"
              :percentage="item.percent"
              :format="
                (p) =>
                  item.error ? '上传失败 请删除后重新上传' : Math.round(p) + '%'
              "
              :color="item.error ? '#f00' : '#007aff'"
            ></el-progress>
          </li>
        </ul>
      </el-form-item>
    </el-form>
    <div class="foot">
      <el-button @click="cancel">取消</el-button>
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import config from "@/api/static/host.js";
import { addNewVisitRecord } from "@/api/lib/api.js";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  components: { quillEditor },
  data() {
    return {
      orderStatusList: [
        { value: 10, label: "待支付" },
        { value: 20, label: "待配车" },
        { value: 30, label: "已配车（待派单）" },
        { value: 40, label: "已派单（待接驾）" },
        { value: 50, label: "司机申请取消接驾" },
        { value: 60, label: "已接驾" },
        { value: 70, label: "已完成" },
        { value: 80, label: "取消接驾" },
        { value: 110, label: "订单取消" },
        { value: 120, label: "已退票" },
        { value: 130, label: "已改签" },
      ],
      ruleForm: {
        interviewWay: 0,
        interviewContent: 0,
      },
      rowInfo: {},
      rules: {
        interviewer: [
          { required: true, message: "回访人不能为空", trigger: "blur" },
        ],
        interviewTime: [
          { required: true, message: "回访时间不能为空", trigger: "change" },
        ],
        interviewWay: [
          { required: true, message: "回访方式不能为空", trigger: "change" },
        ],
        interviewContent: [
          { required: true, message: "回访方式不能为空", trigger: "change" },
        ],
        interviewRecord: [
          { required: true, message: "回访记录不能为空", trigger: "blur" },
        ],
      },
      fileList: [],
      upload: {},
      uploadUrl: config.apiBaseUrl + "manage/uploadImage/file/upload",
      // 富文本编辑器配置
      editorOption: {
        placeholder: "编辑内容",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            [], // 链接、图片、视频
          ],
        },
      },
    };
  },
  computed: {
    fileAccept: function () {
      return `${this.imgAccept.join(",")},${this.videoAccept.join(
        ","
      )},${this.audioAccept.join(",")}`;
    },
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.fileList = [];
    },
    // 载入信息
    loadInfo(row) {
      this.rowInfo = row;
      //清空上次填写数据-表单重置
      // this.$refs.form.resetFields();//时间选择器错误
      this.ruleForm = {
        interviewWay: 0,
        interviewContent: 0,
      };
      this.ruleForm.interviewer = sessionStorage.getItem("realname");
      this.ruleForm.orderId = row.orderId;
      this.ruleForm.lineName = `${row.startStation}-${row.endStation}`;
      this.ruleForm.passengerPhone = row.passengerPhone;
      this.ruleForm.passengerName = row.passengerName ?? row.passengerPhone;
      this.ruleForm.orderCreateTime = row.createTime;
      this.ruleForm.orderStatus = row.orderStatus;
      this.ruleForm.passengerCount = row.passengerCount;
      this.ruleForm.validPassengersNum = row.validPassengersNum;
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },
    // 提交表单
    submit() {
      this.$refs.form?.validate((valid) => {
        if (valid) {
          let req = this.handelData();
          addNewVisitRecord(req).then((res) => {
            this.$message.success("保存成功");
            this.$emit("saveOk");
            this.fileList = [];
          });
        }
      });
    },
    // 提交前处理表单数据
    handelData() {
      const interviewFile = [];
      this.fileList.forEach((item) => {
        if (item.result) {
          interviewFile.push(
            `${item.result}:${item.file.type}:${item.file.name}`
          );
        }
      });
      this.ruleForm.interviewFile = interviewFile;
      return this.ruleForm;
    },
    beforeUpload(file) {
      let reg = new RegExp(/^(audio|video|image)/);
      if (!reg.test(file.type)) {
        this.$message.error("只能上传图片，音、视频文件");
        return false;
      }
      if (
        file.type.indexOf("audio") != -1 ||
        file.type.indexOf("video") != -1
      ) {
        if (file.size >= 100 * 1024 * 1024) {
          this.$message.error("音、视频文件不能大于100M");
          return false;
        }
      } else if (file.type.indexOf("image") != -1) {
        if (file.size >= 10 * 1024 * 1024) {
          this.$message.error("图片文件不能大于10M");
          return false;
        }
      }
      this.upload[file.uid] = {
        file,
        name: file.name,
        percent: 1,
      };
      this.fileList.push(this.upload[file.uid]);
    },
    // 上传失败
    uploadError(res, file) {
      this.$message.error(res);
      this.upload[file.uid].error = true;
    },
    // 上传进度
    uploadProgress(e, file) {
      this.upload[file.uid].percent = e.percent;
    },
    uploadSuccess(res, file) {
      this.upload[file.uid].result = res.data[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.add-visit-form {
  .travelInfo {
    padding: 10px;
    overflow-y: auto;
    background: #f2f2f2;
    margin-bottom: 20px;

    .el-col {
      padding: 8px;

      .label {
        display: inline-block;
        // text-align-last: justify;
        width: 100px;
      }
    }
  }

  .el-form {
    max-height: 45vh;
    padding-right: 20px;
    overflow-y: scroll;
    @include base-scrollbar;

    /deep/.quill-editor {
      line-height: initial;

      .ql-editor {
        height: 250px;
      }
    }
  }

  .foot {
    text-align: right;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ccc;
  }

  .file-list {
    padding: 10px 0;
    line-height: 10px;

    li {
      padding: 5px;

      img {
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .img-name {
      line-height: 20px;
    }

    .delete {
      margin-left: 30px;
      color: #f00;
      cursor: pointer;
    }
  }
}
</style>
