var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-visit-form" },
    [
      _c(
        "div",
        { staticClass: "travelInfo" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("线路名称：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(_vm.rowInfo.startStation) +
                      "-" +
                      _vm._s(_vm.rowInfo.endStation)
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("状态：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.orderStatusList.find(
                        (t) => t.value == _vm.rowInfo.orderStatus
                      ).label
                    )
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("订单编号：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.rowInfo.orderId)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("下单人：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.rowInfo.orderPhone)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("联系电话：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.rowInfo.orderPhone)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("乘客数量：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.rowInfo.passengerCount)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("有效乘车人数："),
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.rowInfo.validPassengersNum)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 8 } }, [
                _c("span", { staticClass: "label" }, [_vm._v("下单时间：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.rowInfo.createTime)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "被访人：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请填写被访人", maxlength: "10" },
                model: {
                  value: _vm.ruleForm.interviewee,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "interviewee", $$v)
                  },
                  expression: "ruleForm.interviewee",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回访人：", prop: "interviewer" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请填写回访人",
                  disabled: "",
                  maxlength: "10",
                },
                model: {
                  value: _vm.ruleForm.interviewer,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "interviewer", $$v)
                  },
                  expression: "ruleForm.interviewer",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回访时间：", prop: "interviewTime" } },
            [
              _c("el-date-picker", {
                attrs: {
                  type: "datetime",
                  "value-format": "timestamp",
                  placeholder: "请选择回访时间",
                },
                model: {
                  value: _vm.ruleForm.interviewTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "interviewTime", $$v)
                  },
                  expression: "ruleForm.interviewTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回访方式：", prop: "interviewWay" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.interviewWay,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "interviewWay", $$v)
                    },
                    expression: "ruleForm.interviewWay",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("电话回访")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("微信回访")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "回访内容：", prop: "interviewContent" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.ruleForm.interviewContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "interviewContent", $$v)
                    },
                    expression: "ruleForm.interviewContent",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [
                    _vm._v("产品使用情况"),
                  ]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("客诉处理")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "initLine",
              attrs: { label: "回访记录：", prop: "interviewRecord" },
            },
            [
              _c("quill-editor", {
                ref: "myTextEditor",
                attrs: { options: _vm.editorOption },
                model: {
                  value: _vm.ruleForm.interviewRecord,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "interviewRecord", $$v)
                  },
                  expression: "ruleForm.interviewRecord",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "上传附件：" } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    name: "files",
                    action: _vm.uploadUrl,
                    multiple: true,
                    "before-upload": _vm.beforeUpload,
                    "on-progress": _vm.uploadProgress,
                    "on-success": _vm.uploadSuccess,
                    "on-error": _vm.uploadError,
                    "show-file-list": false,
                    limit: 9,
                  },
                },
                [
                  _c("el-button", [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v(" 点击上传"),
                  ]),
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "file-list" },
                _vm._l(_vm.fileList, function (item, index) {
                  return _c(
                    "li",
                    { key: index },
                    [
                      item.file.type.indexOf("image") != -1
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/image.svg"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                      item.file.type.indexOf("video") != -1
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/video.svg"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                      item.file.type.indexOf("audio") != -1
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/audio.svg"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "img-name" }, [
                        _vm._v(" " + _vm._s(item.name) + " "),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "delete",
                          on: {
                            click: function ($event) {
                              return _vm.fileList.splice(index, 1)
                            },
                          },
                        },
                        [_vm._v("删除")]
                      ),
                      item.percent
                        ? _c("el-progress", {
                            attrs: {
                              percentage: item.percent,
                              format: (p) =>
                                item.error
                                  ? "上传失败 请删除后重新上传"
                                  : Math.round(p) + "%",
                              color: item.error ? "#f00" : "#007aff",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "foot" },
        [
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }